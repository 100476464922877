/**
 *
 * Components
 * Footer
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE


.c-footer{
	position: relative;
}

// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}