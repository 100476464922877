/**
 *
 * Settings
 * Transitions
 *
 */



/* Variables */

:root{
    --transition-default: .3s;
}