/**
 *
 * Elements
 * Lists
 *
 */

ol,
ul,
dl {
	margin-top: 0;
	font-size: var(--f-p);
	font-family: var(--f-main);
	line-height: var(--f-lh);
	font-weight:300;
	margin-bottom: 1rem;
	color: var(--c-dark-7);
	transition: var(--transition-default);
}
