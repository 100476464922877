/**
 *
 * Elements
 * Texts
 *
 */


p{
	font-size: var(--f-p);
	font-family: var(--f-main);
	line-height: var(--f-lh);
	font-weight:300;
	margin-bottom: 1rem;
	color: var(--c-dark-7);
	transition: var(--transition-default);
}

strong{
	font-weight: 500;
	color: var(--c-dark);
}

blockquote{
	background: var(--c-gray-5);
	padding: 2rem;
	margin-top: 2rem;
	border-radius: 0 var(--radius-2) var(--radius-2) 0;
	border-left: solid var(--c-grey-50);
}

.overtitle{
	font-size: var(--f-overtitle);
	font-family: var(--f-main);
	text-transform: uppercase;
	font-weight:400;
}