/**
 *
 * Settings
 * Radius
 *
 */



 :root {

	// MOBILE
	--radius-1: 1rem;
  --radius-2: 2rem;

	// TABLET
	@include media-up(md){

		--radius-1: 1rem;
    --radius-2: 2rem;

	}

	// DESKTOP
	@include media-up(lg){

		--radius-1: 1rem;
    --radius-2: 2rem;

	}

}