/**
 *
 * Settings
 * Spaces
 *
 */

 :root{

	// MOBILE
	--space-1: 1rem;
	--space-2: 2rem;
	--space-3: 3rem;
	--space-4: 3rem;
	--space-5: 5rem;
	--space-6: 6rem;
	--space-7: 7rem;
	--space-8: 8rem;

	// TABLET
	@include media-up(md){

		--space-1: 1.5rem;
		--space-2: 2.5rem;
		--space-3: 3.5rem;
		--space-4: 4.5rem;
		--space-5: 5.5rem;
		--space-6: 6.5rem;
		--space-7: 9rem;
		--space-8: 12rem;

	}

	// DESKTOP
	@include media-up(lg){

		--space-1: 2rem;
		--space-2: 3rem;
		--space-3: 4rem;
		--space-4: 5rem;
		--space-5: 6rem;
		--space-6: 8rem;
		--space-7: 12rem;
		--space-8: 14rem;

	}

}