/*!
 * Project: Thibaud Cuvelier Portfolio
 * Author: Six Vallées
 * E-mail: thibaud@sixvallees.com
 * Website: thibaudcuvelier.be
 */


@use 'sass:math';

/* Settings */

@import "1-settings/settings.breakpoints.scss";
@import "1-settings/settings.colors.scss";
@import "1-settings/settings.typography.scss";
@import "1-settings/settings.spaces.scss";
@import "1-settings/settings.radius.scss";
@import "1-settings/settings.transitions.scss";


/* Tools */

@import "2-tools/tools.functions.scss";
@import "2-tools/tools.mixins.scss";

/* Generic */

@import "3-generic/generic.reset.scss";

/* Elements */

@import "4-elements/elements.document.scss";
@import "4-elements/elements.headings.scss";
@import "4-elements/elements.texts.scss";
@import "4-elements/elements.links.scss";
@import "4-elements/elements.lists.scss";
@import "4-elements/elements.tables.scss";


/* Objects */

@import "5-objects/objects.container.scss";
@import "5-objects/objects.spacer.scss";
@import "5-objects/objects.panel.scss";
@import "5-objects/objects.media.scss";
@import "5-objects/objects.links.scss";
@import "5-objects/objects.button.scss";
@import "5-objects/objects.hr.scss";
@import "5-objects/objects.label.scss";
@import "5-objects/objects.anim.scss";

/* Components */

@import "6-components/components.skipMain.scss";
@import "6-components/components.header.scss";
@import "6-components/components.footer.scss";
@import "6-components/components.projects.scss";
@import "6-components/components.projectImage.scss";
@import "6-components/components.cookie.scss";

/* Utilities */

@import "7-utilities/utilities.background.scss";
@import "7-utilities/utilities.boxAlignment.scss";
@import "7-utilities/utilities.grid.scss";
@import "7-utilities/utilities.display.scss";
@import "7-utilities/utilities.spacing.scss";
@import "7-utilities/utilities.text.scss";
@import "7-utilities/utilities.list.scss";
@import "7-utilities/utilities.flexDirection.scss";
@import "7-utilities/utilities.justifyContent.scss";
@import "7-utilities/utilities.columns.scss";
@import "7-utilities/utilities.screenReader.scss";
@import "7-utilities/utilities.position.scss";
@import "7-utilities/utilities.index.scss";