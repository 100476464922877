/**
 *
 * Utilities
 * Text
 *
 */

/* Variables */

$txt-colors:(
	name: txt-color,
	property: color,
	value:(
		primary: var(--c-pr),
		dark1: var(--c-dark-1),
		dark2: var(--c-dark-2),
		dark3: var(--c-dark-3),
		dark4: var(--c-dark-4),
		dark5: var(--c-dark-5),
		dark6: var(--c-dark-6),
		dark7: var(--c-dark-7),
		dark8: var(--c-dark-8),
		dark9: var(--c-dark-9),
		dark: var(--c-dark),
		light: var(--c-light)
	)
);

$txt-align:(
	name: txt-align,
	property: text-align,
	value:(
		left: left,
		right: right,
		center: center,
	)
);

/* Styles */

// Colors

@include utility($txt-colors);

// Alignement

@include utility($txt-align);

// Settings

.u-text-no-baseline{
	margin-bottom: 0px;
}