/**
 *
 * Objects
 * HR
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE

.o-hr{
	margin-bottom: 2rem;
	background: var(--c-dark-3);
	height: .2rem;
	border: none;
}


// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}