/**
 *
 * Objects
 * label
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE

.o-label{
	padding: 1rem;
	display: inline-block;
	background: var(--c-pr);
	color: var(--c-sc);
	border-radius: var(--radius-1);
	font-family: var(--f-main);
	font-size: var(--f-p);
	margin-bottom: 1rem;
}

// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}