/**
 *
 * Components
 * Project Image
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE

.c-projectImage{
	border-radius: var(--radius-1);
	padding: 8% 8% 0% 8%;
	.o-image{
		border-radius: 0;
		border-top-right-radius: var(--radius-1);
		border-top-left-radius: var(--radius-1);
		border: .2rem solid var(--c-dark-2);
		border-bottom: 0px;
	}
}

.c-projectImage.--desktopMobile .c-projectImage-mobile{
	padding-top: 2rem;
	max-width: 30rem;
	margin: 0 auto;
}

.c-projectImage.--desktopMobile .c-projectImage-desktop{
	display: none;
}	

// TABLET
@include media-up(md){

	.c-projectImage.--desktopMobile .c-projectImage-desktop{
		display: block;
	}	

	.c-projectImage.--desktopMobile .c-projectImage-mobile{
		padding-top: 0rem;
		max-width: 20%;
	}

	.c-projectImage.--desktopMobile{
		display: flex;
		gap: 8%;
		align-items: flex-end;
	}	

}

// DESKTOP
@include media-up(md){

}