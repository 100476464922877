/**
 *
 * Components
 * Header
 *
 */


/* Variables */

// Menu

$header-menu-padding: 2rem;
$header-menu-width: 20rem;

// Burger

$header-burger-width: 40px;
$header-burger-height: 10px;
$header-burger-thick: 2px;

/* Placeholders */

%header-burgerSpan{
	display: block;
	width: 100%;
	height: $header-burger-thick;
	background: var(--c-dark-9);
	position: absolute;
	transform-origin: center;
	transition: var(--transition-default);
}
%header-menuItem--isActive{
	background: var(--c-pr-tr2) !important;
	border-color: var(--c-pr) !important;
}

// Nav

%header-nav-item-active{
	opacity: 1;
	bottom:.3rem;
}

/* Mixins & Functions */

@mixin header-isMenu(){
	[data-menu-open=true] &{
		@content;
	}
}

/* Styles */


// MOBILE


// nav

.c-header{
	background: var(--c-light);
	z-index: 9;
	position: relative;
}


.c-header-nav{
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1;
	top: 0;
	left: 0;
	padding: 10rem 2rem 2rem 2rem;
	height: calc(100% - 10rem);
	width: calc(100% - 4rem);
	background: var(--c-sc);
	opacity: 0;
	pointer-events: none;
	transition: var(--transition-default);
	@include header-isMenu(){
		opacity: 1;
		pointer-events: initial;
	}
}

.c-header-navList{
	height: calc(100vh - 40rem);
}

.c-header-navItem{
	margin-bottom: 1rem;
	transform: translateY(0rem);
}

.c-header-navItem a{
	display: block;
	text-decoration: none;
	padding: 1.4rem;
	font-family: var(--f-main);
	font-size: 4rem;
	text-align: left;
	&::after{
		content:"";
		width: calc(100% - 2.8rem);
		height: .2rem;
		background: var(--c-pr);
		display: block;
		position: absolute;
		bottom: 0rem;
		transition: var(--transition-default);
		opacity: 0;
	}
	&:hover, &:focus{
		display: block;
		&::after{
			@extend %header-nav-item-active;
		}
	}
}

.c-header-navItem.--isActive a::after{
	@extend %header-nav-item-active;
}



// burger

.c-header-burger{
	width: $header-burger-width;
	height: $header-burger-height;
	position: relative;
	padding: 1rem 0;
	z-index: 99;
	right: 0;
	cursor: pointer;
	@extend %focus;
	span{
		@extend %header-burgerSpan;
		will-change: transform, top;
	}
	span:first-child{
		top:1rem;
		@include header-isMenu(){
			transform: rotate(45deg);
			top: 50%;
		}
	}
	span:last-child{
		bottom:1rem;
		@include header-isMenu(){
			transform: rotate(-45deg);
			top: 50%;
		}
	}
}

[data-menu-open=true]{
	overflow: hidden;
}


// logo 

.c-header-logo{
	font-family: var(--f-main);
	a{
		text-decoration: none;
		display: block;
	}
	span{
		display: block;
	}
}

.c-header-logoName{
	font-weight: 600;
	font-size: 1.8rem;
	line-height: 1.3em;
}

.c-header-logoPosition{
	color: var(--c-dark-5);
	font-size: 1.6rem;
	line-height: 1.3em;
}


// TABLET
@include media-up(md){
	

.c-header-logoName{
	font-size: 2rem;
}

.c-header-logoPosition{
	font-size: 1.8rem;
}

.c-header-nav{
	display: flex !important;
	flex-direction: row;
	padding-top: 0;
	position: relative;
	width: auto;
	height: auto;
	opacity: 1;
	background: transparent;
	pointer-events: visible;
	align-items: center;
	gap: 1rem;
}

.c-header-navItem{
	float: left;
	opacity: 1;
	transform: translateY(0rem);
	margin-bottom: 0;
	a{
		font-size: 2rem;
	}
}

}

// DESKTOP
@include media-up(lg){


}