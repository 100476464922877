/**
 *
 * Objects
 * Container
 *
 */


.o-container{
	max-width: #{map-get($breakpoints,'lg')}px;
	margin: 0 auto;
	padding: 0 3rem;
	position: relative;
	z-index: 1;
}


// TABLET
@include media-up(sm){

.o-container{
	padding: 0;
	width: 90%;
}

}

// DESKTOP
@include media-up(lg){

}