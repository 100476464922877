/**
 *
 * Objects
 * Links
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE


button.o-link{
	background: none;
	border: none;
}

.o-link{
	font-size: var(--f-p);
	font-family: var(--f-main);
	line-height: 1.5em;
	display: inline-block;
	transition: var(--transition-default);
	text-decoration: none;
	border-bottom: .2rem solid transparent;
	padding: 0 0 .5rem 0;
	margin: 0 1rem .8rem 0;
	&:hover, &:focus{
		border-bottom: .2rem solid var(--c-pr);
	}
}



.o-link.--icon{
	display: inline-flex;
	gap: .5rem;
	align-items: center;
	span{
		font-size: var(--f-p);
		text-decoration: none;
		display:inline-block;
		color: var(--c-pr);
		transition: var(--transition-default);
	}
	&:hover span{
		transform: translateX(.3rem);
	}
}

.o-link.--highlight{
	border-bottom: .2rem solid var(--c-pr);
	color: var(--c-dark-6);
	&:hover, &:focus{
		color: var(--c-dark);
	}
}

// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}