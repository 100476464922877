/**
 *
 * Components
 * Skip main
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */

.c-skipMain{
	top: 1rem;
	left: -100%;
	position: absolute;
	overflow: hidden;
	z-index: -999;
	transition: var(--transition-default);
	font-family: var(--f-main);
	text-decoration: none;
	&:focus{
		color: var(--c-light);
    background-color: var(--c-dark);
    left: 1rem;
    overflow: auto;
    padding: 1rem 1.5rem;
    text-align: center;
    font-size: var(--f-h5);
    z-index: 999999;
	}
}

// MOBILE


// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}