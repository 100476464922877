/**
 *
 * Elements
 * Document
 *
 */


html {
	font-size: 2.5vw;
	color: var(--c-dark);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body{
	transition: var(--transition-default);
	border-top: var(--c-pr) solid .3rem;
	border-bottom: var(--c-pr) solid .3rem;
	background: var(--c-pr);
}

section{
	background: var(--c-light);
}

main{
	min-height: 70vh;
	background: var(--c-light);
}

::selection {
	background: var(--c-pr);
	color: var(--c-dark);
}

// MOBILE
@include media-up(sm){
	html{
		font-size: 1.2vw;
	}
}

// TABLET
@include media-up(md){
	html{
		font-size: 62.5%;
	}
}