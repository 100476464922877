/**
 *
 * Components
 * Cookie
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE

.c-cookie{
	margin: 0 auto;
	border-radius: var(--radius-2) ;
	position: fixed;
	bottom: 5%;
	right: 5%;
	width: calc(90% -  4rem);
	max-width: 50rem;
	padding: 2rem;
	background: rgba(#000,.7);
	backdrop-filter: blur(5rem);
	border: solid .2rem var(--c-dark-3);
	z-index: 99;
}

// TABLET
@include media-up(md){
	.c-cookie{
		right: 10%;
	}
}

// DESKTOP
@include media-up(lg){
	.c-cookie{
		right: calc((100vw - #{map-get($breakpoints,'lg')}px)/2);
	}
}