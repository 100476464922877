/**
 *
 * Components
 * project
 *
 */



/* Variables */

$projects-content-padding: 2rem;

/* Placeholders */

/* Mixins & Functions */

/* Styles */


// MOBILE

.c-project{
	display: flex;
	border-radius: var(--radius-2);
	overflow: hidden;
	margin-bottom: 3rem;
	flex-direction: column;
	position: relative;
}
.c-project-content{
	width: calc(100% - $projects-content-padding*2);
	padding: $projects-content-padding;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.c-project-media{
	width: 100%;
	min-height: 20rem;
}

// MOBILE

@include media-up(sm){
	.c-project{
		flex-direction: row;
	}
	.c-project-content{
		width: calc(60% - $projects-content-padding*2);
	}
	.c-project-media{
		width: 40%;
		min-height: 25rem;
	}
}

// TABLET
@include media-up(md){
	.c-project-content{
		padding: 3rem;
	}
	.c-project-content{
		width: calc(70% - $projects-content-padding*2);
	}
	.c-project-media{
		width: 30%;
		min-height: 30rem;
	}
}

// DESKTOP
@include media-up(lg){

	.c-project{
		margin-bottom: 5rem;
	}
	.c-project-content{
		width: calc(75% - $projects-content-padding*2);
	}
	.c-project-media{
		width: 25%;
	}

}