/**
 *
 * Objects
 * Media
 *
 */



/* Variables */

/* Placeholders */

%blurUp{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: var(--c-dark-tr2);
	backdrop-filter: blur(10rem);
	transition: .3s;
	transition-delay: .3s;
}

/* Mixins & Functions */

/* Styles */

// MOBILE

.o-image{
	border-radius: var(--radius-1);
	overflow: hidden;
	position: relative;
	& img{
		display: inline-block;
		width: 100%;
		height: auto;
		opacity: 1;
	}
}

[data-bg]{
	overflow: hidden;
	position: relative;
}

.o-image.--blur::before{
	@extend %blurUp;
}

[data-bg].--blur::before{
	@extend %blurUp;
}
.o-image.loaded::before{
	opacity: 0;
}
[data-bg].loaded::before{
	opacity: 0;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

// TABLET
@include media-up(sm){

}

// DESKTOP
@include media-up(md){

}