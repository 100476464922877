/**
 *
 * Objects
 * Spacer
 *
 */

 
@for $i from 1 through 8 {
	.o-spacer-#{$i}{
		height: var(--space-#{$i});
	}
}

.o-fe{
	height: var(--space-8);
}

.o-le{
	height: var(--space-8);
}