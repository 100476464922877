/**
 *
 * Objects
 * Panel
 *
 */


.o-panel{
	padding: 2rem 2rem 1rem 2rem;
	border-radius: var(--radius-1);
}

.o-panel.--big{
	padding: 4rem 4rem 3rem 4rem;
	border-radius: var(--radius-2);
}