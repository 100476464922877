/**
 *
 * Elements
 * Links
 *
 */


%link {
	color: var(--c-dark);
	text-decoration: underline;
	border: dotted .2rem transparent;
	outline: none;
	line-height: var(--f-lh);
}

%focus{
	&:focus{
		border: dotted .2rem var(--c-dark-5);
		outline: none;
	}
	@include no-tab{
		border: .2rem solid transparent;
		outline: none;
	}
}

a{
	@extend %link;
	@extend %focus;
}

.is-clickable{
	cursor: pointer;
}