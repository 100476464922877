/**
 *
 * Objects
 * Blob
 *
 */



/* Variables */

/* Placeholders */

/* Mixins & Functions */

/* Styles */

.o-anim.--blob{
	position: absolute;
	top: -5%;
	left: calc((100vw - #{map-get($breakpoints,lg)+px})/2);
	height: 100vh;
	width: 100%;
	max-width: map-get($breakpoints,lg)+px;
	z-index: 0;
	svg{
		margin-left: -20%;
	}
}

.o-anim.--signature{
	position: relative;
	svg{
		position: absolute;
		top: 30%;
		left: -2rem;
		z-index: -1;
	}
}

.o-anim.--circle{
	position: relative;
	svg{
		position: absolute;
		top: -8rem;
		left: -10rem;
		z-index: -1;
		path{
			stroke-dashoffset: 1438px;
			transition: 1s ease-in-out;
		}
	}
}


[data-aos="circle"] {
	svg path{
		stroke-dashoffset: 1438px;
		transition: 1s;
	}
  &.aos-animate svg path {
    stroke-dashoffset: 0px;
  }
}

// aos

[data-aos="card"] {
  transform-origin: center center;
	transform: translateY(-3rem);
	opacity: 0;
	transition-property: transform,opacity,height;
  &.aos-animate {
		transform: translateY(0rem);
    opacity: 1;
  }
}

// MOBILE


@include media-up(sm){

	.o-anim.--blob{
		top: -10%;
	}

}


// TABLET
@include media-up(md){

	.o-anim.--blob{
		top: 5%;
	}

}

// DESKTOP
@include media-up(lg){

	.o-anim.--blob{
		top: 10%;
	}

}